const GlossaryView = () => {
    return (
        <div className="h-full flex flex-col gap-6 p-6 bg-gray-100">
            <div className="text-4xl font-bold text-center mb-6">Glosario</div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Selección Dato Venta</h2>
                <p className="mb-4">
                    Es el dato a utilizar para proyectar la venta de un producto en el mes y comprar en consecuencia.
                    Se puede elegir entre:
                </p>
                <ul className="list-disc list-inside ml-4">
                    <li>Último Mes: ventas del mes anterior</li>
                    <li>Últimos 3 Meses: promedio de venta de los últimos 3 meses</li>
                    <li>Últimos 6 Meses: promedio de venta de los últimos 6 meses</li>
                    <li>Últimos 9 Meses: promedio de venta de los últimos 9 meses</li>
                    <li>Últimos 12 Meses: promedio de venta de los últimos 12 meses</li>
                    <li>WMA 3 Meses: fórmula WMA según la temporalidad del producto</li>
                    <li>WMA 6 Meses: fórmula WMA según la temporalidad del producto</li>
                    <li>WMA 9 Meses: fórmula WMA, no aplica para productos de verano e invierno</li>
                    <li>WMA 12 Meses: fórmula WMA, no aplica para productos de verano e invierno</li>
                    <li>Seleccionar Meses: botón para seleccionar meses y calcular un promedio lineal</li>
                    <li>Proyectado del Mes: basado en órdenes pendientes y ventas actuales para proyectar la venta del mes</li>
                </ul>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Meses en Bodega</h2>
                <p>
                    Los meses de stock que se desea tener en bodega de acuerdo a la venta seleccionada. Se puede editar para todos los productos o para un producto en particular haciendo doble clic en el valor de la fila.
                </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Incluir Recepción en Meses Venta</h2>
                <p>
                    Permite incluir o no los pendientes de entrega de los proveedores al calcular la cantidad de meses de stock en bodega.
                </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Temporalidad y Crecimiento Esperado</h2>
                <p>
                    Permite multiplicar el valor de venta seleccionado por 1 + el % de crecimiento esperado y 1 + el % de temporalidad, para obtener un valor proyectado de venta más realista al calcular la compra.
                </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Clasificación de Productos</h2>
                <p className="mb-4">
                    Los productos se clasifican en 3 categorías: A, B y C, para priorizar la compra de los más importantes.
                </p>
                <ul className="list-disc list-inside ml-4">
                    <li>Productos A: componen el 80% de la venta</li>
                    <li>Productos B: componen el 15% de la venta</li>
                    <li>Productos C: componen el 5% restante de la venta</li>
                </ul>
                <p className="mt-4">
                    Se pueden usar distintos criterios para clasificar un producto como A, B o C, utilizando el selector de periodo para clasificación, que permite seleccionar la participación de cada SKU en el último mes, 3 meses, 6 meses, 9 meses o 12 meses.
                </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Productos Nuevos</h2>
                <p>
                    Al seleccionar esta opción, se muestran solo productos nuevos. Si no está seleccionada, se muestran todos los productos.
                </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Temporada</h2>
                <p>
                    Los productos pueden clasificarse como TT (Todo el año), V (Verano) o I (Invierno), se asignan desde la carga masiva de productos y esta condición afecta el cálculo del WMA.
                </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Fórmulas WMA</h2>
                <p className="mb-4">
                    Las fórmulas WMA se aplican según la temporalidad del producto. Para productos TT, Verano e Invierno se utilizan diferentes fórmulas y periodos, ajustados al momento del año.
                </p>
                <ul className="list-disc list-inside ml-4 mb-2">
                    <li>Para 3 meses productos TT: 0,62 / 0,26 / 0,12</li>
                    <li>Para 6 meses productos TT: 0,37 / 0,26 / 0,18 / 0,13 / 0,09 / 0,06</li>
                    <li>Para 9 meses productos TT: 0,23 / 0,16 / 0,11 / 0,08 / 0,05 / 0,04 / 0,03 / 0,02 / 0,01</li>
                    <li>Para 12 meses productos TT: 0,17 / 0,12 / 0,08 / 0,06 / 0,04 / 0,03 / 0,02 / 0,01 / 0,01 / 0,01 / 0,01 / 0,01</li>
                </ul>
                <p className="mb-4">
                    Para productos de Verano
                    Si estamos antes del 30 de abril, se calcula utilizando los datos del año anterior, si estamos despues del 30 de abril, se calcula con los datos del año actual.
                </p>
                <ul className="list-disc list-inside ml-4 mb-2">
                    <li>Para 3 meses se considera Noviembre, Diciembre y Enero: 0,25 / 0,5 / 0,25</li>
                    <li> Para 6 meses se considiera Octubre a Marzo: 0,15 / 0,20 / 0,30 / 0,20 / 0,10 / 0,05</li>
                </ul>
                <p className="mb-4">
                    Para productos de Invierno
                    Si estamos antes del 30 de septiembre, se calcula utilizando los datos del año anterior, si estamos despues del 30 de septiembre, se calcula con los datos del año actual.
                </p>
                <ul className="list-disc list-inside ml-4 mb-4">
                    <li> Para 3 meses se considera Marzo, Abril y Mayo: 0,25 / 0,5 / 0,25</li>
                    <li> Para 6 meses se considera de Marzo a Agosto: 0,15 / 0,20 / 0,30 / 0,20 / 0,10 / 0,05</li>
                </ul>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-2">Descarga de Sábana Completa</h2>
                <p>
                    Al descargarse la sábana completa de datos, se incluyen las 5 posibles clasificaciones de productos.
                </p>
            </div>
        </div>
    );
}

export default GlossaryView;